import { getSrc } from "gatsby-plugin-image"
import React from 'react'
import GalleryLayout from '../components/photogallery/galleryLayout'
import { graphql } from 'gatsby'
import Gallery from '../components/photogallery/gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

class BWCA15 extends React.Component {
  render() {
    const { data } = this.props
    const title = 'Boundary Waters Canoe Area, 2015'
    const altOriginal = 'A photo for the album ' + title
    const altThumb = 'A thumbnail photo for the album ' + title
    let items = []
    data.allFile.edges.map(pic =>
      items.push({
        original: getSrc(pic.node.childImageSharp.gatsbyImageData),
        thumbnail: getSrc(pic.node.childImageSharp.gatsbyImageData),
        originalAlt: altOriginal,
        thumbnailAlt: altThumb,
      })
    )

    return (
      <GalleryLayout>
        <Gallery items={items} title={title} />
      </GalleryLayout>
    )
  }
}

export default BWCA15

export const query = graphql`query Bwca15Query {
  allFile(filter: {relativeDirectory: {eq: "bwca15"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
          )
        }
      }
    }
  }
}
`
